import { LogoProps } from "atomicComponents/organisms/Header/components/LogoButton";

export const Logo = ({ small }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={small ? "48.5pt" : "85pt"}
      height={small ? "20pt" : "35pt"}
      viewBox="0 0 300.000000 126.000000"
      preserveAspectRatio="xMidYMid meet"
      data-testid="logo"
    >
      <g
        transform="translate(0.000000,126.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M940 1227 c-14 -7 -37 -20 -50 -31 -20 -14 -22 -19 -10 -24 31 -11 79 -69 89 -108 11 -42 2 -103 -20 -132 -53 -70 -133 -122 -189 -122 -48 0 -102 22 -139 56 -44 39 -61 42 -86 14 -40 -44 25 -128 122 -156 117 -34 240 4 333 102 60 64 58 64 145 -6 145 -117 368 -101 515 36 l50 47 55 -52 c73 -69 132 -95 215 -95 76 0 130 23 178 79 43 48 50 81 24 107 -26 27 -55 17 -81 -28 -36 -61 -112 -79 -187 -45 -103 47 -134 174 -62 257 30 34 30 35 12 56 -26 29 -92 58 -134 58 -51 0 -111 -31 -156 -80 -111 -121 -278 -119 -392 5 -24 26 -59 53 -78 61 -40 17 -119 17 -154 1z" />
        <path d="M2053 1138 l-62 -62 -24 22 c-13 12 -33 22 -45 22 -27 0 -46 -33 -33 -58 13 -25 88 -92 102 -92 16 0 169 151 179 175 4 11 4 28 -2 38 -17 32 -51 19 -115 -45z" />
        <path d="M784 1058 l-54 -51 -24 22 c-32 30 -70 25 -74 -10 -2 -20 8 -36 47 -73 60 -55 57 -56 154 38 66 64 79 90 55 114 -22 22 -49 12 -104 -40z" />
        <path d="M50 667 l-25 -13 -3 -297 c-2 -286 -2 -297 18 -317 28 -28 75 -26 105 5 23 22 25 32 25 120 0 89 1 95 20 95 68 0 130 -64 150 -155 6 -27 18 -58 27 -67 20 -22 71 -23 101 -2 45 31 18 151 -51 222 l-31 32 37 31 c50 41 67 77 67 140 0 106 -71 188 -181 209 -74 14 -229 12 -259 -3z m282 -167 c18 -19 28 -36 24 -46 -3 -9 -6 -20 -6 -25 0 -19 -61 -39 -120 -39 l-60 0 0 70 0 70 66 0 c60 0 68 -3 96 -30z" />
        <path d="M1840 674 c-38 -16 -40 -30 -40 -326 0 -360 -16 -332 179 -326 120 3 142 7 191 29 133 63 203 200 179 353 -13 76 -40 128 -95 182 -66 64 -132 85 -279 90 -66 2 -127 1 -135 -2z m269 -169 c67 -34 98 -102 89 -190 -10 -86 -86 -145 -186 -145 l-52 0 0 181 0 182 60 -6 c33 -4 73 -13 89 -22z" />
        <path d="M2603 592 c-157 -56 -241 -223 -188 -373 62 -175 246 -250 412 -169 217 107 214 420 -5 529 -61 31 -154 36 -219 13z m165 -152 c51 -31 74 -76 69 -136 -4 -62 -28 -97 -84 -125 -46 -22 -85 -20 -133 8 -65 38 -90 138 -51 201 44 69 133 92 199 52z" />
        <path d="M2712 355 c-44 -50 -48 -52 -73 -29 -20 18 -39 11 -39 -13 0 -24 49 -83 68 -82 25 0 114 114 110 141 -5 36 -23 31 -66 -17z" />
        <path d="M1072 564 c-22 -15 -22 -18 -22 -260 0 -231 1 -245 20 -264 27 -27 71 -26 98 3 21 22 22 33 22 194 0 152 2 172 18 186 24 22 65 22 85 0 12 -15 16 -51 19 -191 3 -169 4 -174 27 -193 32 -26 67 -24 96 6 25 24 25 27 25 193 0 151 2 171 18 185 24 22 65 21 85 -1 14 -16 17 -44 17 -186 0 -164 0 -167 25 -191 31 -32 69 -32 100 0 25 24 25 27 25 187 0 187 -8 224 -56 278 -62 68 -167 80 -224 25 l-28 -27 -38 28 c-47 36 -129 41 -163 10 -20 -18 -21 -18 -51 8 -36 30 -66 33 -98 10z" />
        <path d="M664 541 c-149 -86 -169 -315 -39 -446 96 -95 219 -99 312 -9 51 48 56 82 17 113 -34 27 -40 26 -93 -9 -59 -40 -94 -39 -137 4 -60 60 -51 66 86 66 125 0 171 11 184 45 12 33 5 96 -15 140 -55 118 -200 162 -315 96z m174 -117 c12 -8 22 -24 22 -35 0 -17 -7 -19 -85 -19 -92 0 -100 6 -60 45 29 29 88 34 123 9z" />
      </g>
    </svg>
  );
};
