import { memo } from "react";

const LockImage = (): JSX.Element => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3975 8395 c-35 -34 -34 -86 1 -114 30 -24 43 -26 78 -10 50 23 57
    103 13 134 -31 22 -65 18 -92 -10z"
        />
        <path
          d="M6143 8398 c-29 -32 -34 -53 -22 -88 28 -80 149 -56 149 30 0 67 -84
    105 -127 58z"
        />
        <path
          d="M4855 8351 c-449 -83 -819 -301 -1064 -626 -156 -207 -227 -354 -283
    -587 -22 -90 -23 -113 -28 -737 l-5 -644 -57 -13 c-191 -44 -356 -214 -403
    -417 -14 -59 -15 -215 -13 -1310 3 -1179 4 -1245 22 -1297 96 -286 357 -501
    666 -550 42 -6 546 -10 1426 -10 1480 0 1440 -1 1591 55 245 93 450 318 497
    548 14 67 16 223 16 1289 0 1050 -2 1222 -15 1277 -48 200 -211 368 -402 413
    l-52 12 -4 636 c-3 552 -6 644 -21 705 -104 434 -320 749 -675 986 -180 121
    -374 203 -597 254 -161 37 -444 45 -599 16z m481 -206 c112 -20 236 -57 322
    -97 37 -18 69 -33 71 -35 6 -5 -17 -44 -44 -76 l-25 -28 -82 40 c-97 47 -213
    77 -359 92 -223 23 -472 -25 -708 -137 -129 -61 -240 -137 -355 -246 -91 -85
    -97 -93 -92 -122 3 -17 8 -50 12 -73 l6 -42 -36 10 c-56 16 -95 -12 -175 -121
    -55 -76 -123 -187 -96 -157 29 32 30 28 10 -30 -8 -24 -26 -98 -40 -165 -26
    -123 -40 -355 -48 -773 -1 -77 -4 63 -5 312 -2 446 -2 453 23 577 53 266 165
    470 370 672 95 93 230 191 344 249 134 67 343 134 480 154 97 15 332 12 427
    -4z m693 -309 c79 -58 237 -229 299 -324 58 -89 115 -208 126 -267 6 -29 6
    -29 -11 5 -56 112 -199 267 -267 290 -21 7 -43 24 -55 44 -21 34 -137 148
    -185 182 l-29 21 34 41 c19 23 36 42 38 42 2 0 24 -15 50 -34z m-753 -31 c258
    -39 484 -160 671 -359 133 -142 219 -309 264 -512 20 -91 22 -132 26 -526 l5
    -428 -60 0 c-39 0 -62 -4 -65 -12 -10 -32 -17 222 -12 457 8 340 -4 485 -49
    617 -48 141 -113 244 -226 358 -193 195 -439 300 -706 300 -427 0 -798 -245
    -946 -626 -54 -139 -58 -187 -58 -632 0 -224 -3 -417 -6 -429 -6 -20 -13 -23
    -60 -23 -30 0 -55 -3 -55 -7 -1 -5 -5 -62 -9 -128 -4 -74 -7 84 -8 406 -2 444
    0 540 14 620 42 244 155 452 342 631 94 89 143 124 259 182 106 54 227 92 348
    110 121 19 207 19 331 1z m2 -371 c144 -30 276 -101 386 -209 121 -120 180
    -247 196 -428 5 -56 9 -312 10 -569 l0 -468 -755 0 -755 0 0 525 c0 604 2 621
    77 775 38 77 61 109 123 170 104 104 264 185 408 210 81 13 229 11 310 -6z
    m1474 -1894 c56 -16 156 -88 194 -139 53 -73 74 -148 74 -268 l0 -103 -54 0
    -54 0 -4 108 c-4 121 -23 175 -77 230 -74 73 85 67 -1699 70 -1045 1 -1617 -1
    -1641 -8 -90 -24 -165 -120 -178 -228 l-6 -52 -43 0 -44 0 0 -40 c0 -23 -5
    -40 -11 -40 -8 0 -10 31 -7 103 6 148 52 243 152 312 99 68 -21 64 1751 64
    1067 1 1622 -3 1647 -9z m-2702 -405 c73 -38 102 -143 60 -213 -42 -69 -141
    -94 -204 -52 -41 26 -76 88 -76 130 2 107 127 183 220 135z m1163 -300 c236
    -50 406 -292 368 -522 -19 -109 -60 -186 -145 -268 l-73 -70 4 -235 c2 -143 8
    -244 15 -257 8 -17 8 -26 -1 -37 -8 -9 -13 -66 -14 -170 l-2 -156 -127 -127
    -128 -128 -130 130 -130 130 0 426 0 427 -69 67 c-155 149 -189 337 -98 530
    45 95 122 171 219 220 110 54 195 65 311 40z m-1473 -104 c93 -68 92 -194 -2
    -251 -131 -80 -279 85 -193 213 14 20 27 37 28 37 1 0 20 7 42 15 46 17 89 12
    125 -14z m2906 -340 c76 -34 120 -126 100 -206 -11 -44 -71 -101 -121 -115
    -169 -47 -289 173 -159 291 54 50 115 60 180 30z m-3191 -150 c75 -34 125
    -122 110 -194 -32 -155 -234 -198 -320 -69 -64 96 -32 217 69 263 52 23 90 23
    141 0z m575 -856 c135 -69 130 -276 -7 -337 -157 -69 -313 105 -234 260 44 86
    154 121 241 77z m2565 -254 c69 -32 105 -86 105 -157 0 -105 -104 -190 -202
    -164 -149 41 -175 245 -39 317 53 28 83 29 136 4z m-3351 -239 c10 -12 30 -22
    44 -24 22 -3 29 -12 48 -65 37 -110 111 -191 226 -249 110 -56 45 -54 1552
    -54 1266 -1 1396 1 1446 16 168 50 288 157 330 293 17 55 18 56 60 59 36 3 44
    8 56 35 15 31 15 31 10 -30 -18 -209 -179 -390 -416 -470 -52 -17 -121 -18
    -1485 -18 l-1430 0 -76 27 c-164 58 -295 165 -359 294 l-35 69 -1 175 c-1 143
    0 156 5 68 5 -83 10 -110 25 -126z"
        />
        <path
          d="M5365 6811 c-48 -20 -72 -74 -55 -125 23 -71 106 -89 159 -35 76 76
    -5 200 -104 160z"
        />
        <path
          d="M4798 6777 c-95 -89 15 -237 122 -165 58 39 65 114 15 164 -33 33
    -102 34 -137 1z"
        />
        <path
          d="M5151 6376 c-31 -17 -50 -53 -51 -94 0 -43 55 -102 94 -102 40 0 84
    26 102 61 46 89 -57 184 -145 135z"
        />
        <path
          d="M4725 6278 c-49 -28 -59 -78 -24 -122 44 -56 129 -19 129 56 0 28 -7
    42 -26 57 -30 24 -50 26 -79 9z"
        />
        <path
          d="M5050 4608 c-55 -14 -112 -59 -145 -113 -26 -42 -30 -58 -30 -120 0
    -62 4 -78 30 -121 44 -70 119 -114 197 -114 32 0 75 7 96 15 49 21 110 80 133
    130 57 122 -19 280 -155 320 -55 17 -75 17 -126 3z"
        />
        <path
          d="M3486 8124 c-58 -59 -20 -154 62 -154 56 0 92 35 92 90 0 84 -95 123
    -154 64z"
        />
        <path
          d="M6698 8134 c-54 -29 -64 -93 -22 -143 21 -25 33 -31 65 -31 100 0
    133 132 44 174 -40 19 -52 19 -87 0z"
        />
        <path
          d="M6925 7886 c-181 -84 -222 -311 -81 -451 141 -141 366 -94 449 94 28
    64 21 181 -16 244 -71 121 -228 171 -352 113z"
        />
        <path
          d="M7652 7647 c-30 -32 -29 -81 3 -112 72 -73 178 28 114 109 -27 35
    -86 36 -117 3z"
        />
        <path
          d="M3365 7595 c-47 -47 -19 -119 46 -117 66 2 94 81 43 121 -35 27 -59
    26 -89 -4z"
        />
        <path
          d="M2899 7555 c-40 -22 -49 -63 -49 -216 l0 -139 -102 0 c-78 0 -111 -4
    -137 -18 -41 -20 -55 -58 -39 -103 16 -47 52 -59 175 -59 l108 0 -3 -132 c-3
    -139 4 -177 42 -203 33 -24 74 -18 107 14 l29 29 0 146 0 146 103 0 c110 0
    138 9 170 52 19 25 19 30 6 65 -21 53 -46 63 -171 63 l-108 0 0 154 c0 146 -1
    156 -22 180 -28 32 -74 41 -109 21z"
        />
        <path
          d="M7165 6983 c-103 -25 -193 -307 -165 -514 25 -180 100 -330 227 -451
    61 -57 176 -138 198 -138 21 0 25 -107 25 -863 0 -799 -2 -764 55 -817 l27
    -25 276 -3 c309 -3 334 1 372 63 20 32 20 52 20 879 l0 846 26 23 c51 43 140
    163 176 237 72 145 97 342 64 485 -24 101 -66 202 -101 242 -27 32 -38 38 -73
    38 -89 0 -115 -71 -66 -181 40 -90 54 -151 54 -244 0 -134 -32 -227 -114 -338
    -53 -72 -157 -153 -236 -183 -76 -30 -223 -37 -308 -15 -108 27 -171 64 -258
    151 -95 94 -133 165 -160 295 -21 107 -9 203 42 323 42 101 43 129 5 168 -29
    28 -44 32 -86 22z m703 -1189 c6 -11 35 -48 66 -81 l56 -61 0 -71 0 -71 -42
    -27 c-65 -41 -88 -64 -88 -88 0 -33 55 -111 88 -126 31 -14 42 -34 42 -79 0
    -21 -11 -30 -63 -55 -45 -22 -63 -36 -65 -52 -4 -26 39 -91 92 -142 34 -33 36
    -39 36 -97 l0 -61 -60 -37 c-48 -30 -60 -42 -60 -62 0 -29 42 -94 89 -138 30
    -28 31 -33 31 -108 l0 -78 -170 0 -170 0 0 728 0 727 104 0 c97 1 106 -1 114
    -21z"
        />
        <path
          d="M7652 6965 c-117 -36 -202 -152 -202 -276 0 -148 140 -289 286 -289
    103 0 213 71 260 166 109 223 -107 473 -344 399z"
        />
        <path
          d="M2552 6565 c-63 -19 -107 -58 -137 -120 -23 -47 -27 -65 -23 -122 3
    -57 9 -76 36 -116 74 -106 192 -135 311 -76 51 25 67 40 91 82 39 69 46 121
    25 194 -22 75 -81 135 -156 158 -63 18 -90 18 -147 0z"
        />
        <path
          d="M1919 6555 c-54 -30 -60 -107 -12 -145 34 -27 76 -25 110 4 35 30 39
    95 8 126 -27 26 -74 33 -106 15z"
        />
        <path
          d="M3041 6414 c-24 -20 -31 -34 -31 -61 0 -64 33 -102 90 -103 69 0 112
    59 90 125 -21 64 -96 84 -149 39z"
        />
        <path
          d="M2895 5942 c-77 -26 -100 -104 -47 -163 23 -26 36 -32 71 -32 47 0
    94 36 105 79 10 39 -19 93 -58 109 -38 16 -41 16 -71 7z"
        />
        <path
          d="M1782 5933 c-7 -3 -19 -18 -28 -35 -21 -40 -11 -91 23 -118 25 -19
    39 -20 355 -20 362 0 374 2 398 59 15 36 7 74 -20 101 -19 19 -34 20 -367 19
    -192 0 -354 -3 -361 -6z"
        />
        <path
          d="M1773 5506 c-22 -19 -28 -32 -28 -66 0 -34 6 -47 28 -66 l28 -24 419
    0 419 0 28 24 c39 34 39 98 0 132 l-28 24 -419 0 -419 0 -28 -24z"
        />
        <path
          d="M8479 5371 c-23 -19 -24 -25 -29 -198 -3 -98 -2 -192 3 -210 17 -68
    96 -86 141 -32 26 31 26 31 26 220 0 188 0 190 -25 214 -30 31 -83 33 -116 6z"
        />
        <path
          d="M2224 5061 c-78 -26 -136 -65 -194 -128 -166 -181 -156 -449 24 -629
    135 -135 319 -169 491 -90 63 28 155 112 190 172 81 138 88 322 17 459 -49 96
    -162 189 -267 220 -70 20 -196 18 -261 -4z m206 -196 c61 -21 124 -77 150
    -130 33 -68 26 -174 -15 -241 -80 -129 -261 -153 -369 -48 -20 19 -45 53 -56
    75 -99 193 89 415 290 344z"
        />
        <path
          d="M1633 4925 c-62 -27 -67 -123 -8 -161 40 -26 85 -20 118 16 44 50 34
    115 -23 145 -35 18 -46 18 -87 0z"
        />
        <path
          d="M8473 4425 c-39 -17 -53 -42 -53 -93 0 -33 6 -45 32 -67 26 -22 39
    -26 67 -21 47 7 81 46 81 92 0 48 -15 73 -55 90 -40 16 -32 17 -72 -1z"
        />
        <path
          d="M1789 4151 c-17 -18 -29 -40 -29 -56 0 -34 50 -85 84 -85 41 0 80 41
    80 85 0 29 -7 43 -28 61 -37 32 -71 30 -107 -5z"
        />
        <path
          d="M7469 4001 c-20 -20 -29 -39 -29 -61 0 -39 47 -90 83 -90 41 0 87 46
    87 85 0 47 -8 64 -41 80 -42 22 -67 18 -100 -14z"
        />
        <path
          d="M7634 3729 c-68 -26 -132 -84 -169 -150 -23 -44 -30 -69 -33 -135 -6
    -111 14 -167 84 -243 72 -78 133 -105 234 -105 161 -1 284 102 320 266 24 109
    -16 225 -106 307 -88 80 -217 104 -330 60z"
        />
        <path
          d="M2264 3590 c-357 -5 -383 -6 -403 -24 -32 -29 -29 -105 5 -137 l26
    -24 413 0 413 0 26 24 c34 32 41 72 21 111 -17 32 -69 62 -102 58 -10 -2 -190
    -5 -399 -8z"
        />
        <path
          d="M2135 3228 c-11 -6 -26 -18 -32 -26 -20 -25 -16 -86 8 -116 l20 -26
    190 0 c112 0 198 4 210 10 23 13 49 61 49 90 0 12 -13 35 -29 51 l-29 29 -184
    0 c-113 0 -191 -5 -203 -12z"
        />
        <path
          d="M7594 2901 c-59 -36 -65 -112 -12 -156 47 -40 116 -27 144 26 18 37
    18 65 -3 99 -29 50 -78 62 -129 31z"
        />
        <path
          d="M2668 2850 c-27 -28 -30 -36 -25 -73 7 -50 44 -87 88 -87 77 0 124
    65 95 133 -27 66 -106 79 -158 27z"
        />
        <path
          d="M4453 1969 c-63 -18 -79 -101 -30 -147 20 -18 38 -20 237 -23 118 -2
    277 -3 353 -1 121 2 139 4 157 22 27 27 35 65 19 101 -23 56 -44 59 -391 58
    -172 0 -328 -5 -345 -10z"
        />
        <path
          d="M5430 1940 c-26 -26 -25 -60 1 -94 57 -72 157 16 103 92 -20 29 -76
    30 -104 2z"
        />
        <path
          d="M4050 1860 c-43 -43 -10 -120 52 -120 59 0 93 84 50 123 -25 23 -78
    21 -102 -3z"
        />
      </g>
    </svg>
  );
};

export default memo(LockImage);
